<template>
  <td style="white-space:nowrap;" v-for="(value, keyName) in row"
   :key="keyName" v-show="checkList==undefined || checkList.length==0 || checkList.includes(keyName)" 
   :class="{'py-0': keyName === 'reason'}
   
   ">
   <div class="table-data-container2" v-if="keyName === 'reason'">
      <div v-show="value">
        <a :href="value" @click.prevent="togglePopup" class="btn btn-white waves-effect dropdown-toggle my-1 px-3"><i class="mdi mdi-dock-window"></i></a>
        <transition name="fade">
          <div v-show="isPopup" class="modal popup-modal">
            <div class="modal-dialog">
              <div class="popup modal-content">
                <div class="modal-header">
                  <button type="button" class="close" @click="isPopup = false">
                    <span>×</span>
                  </button>
                </div>
                <div class="modal-body embed-responsive embed-responsive-16by9" v-if="isPopup">
                  <iframe :src="value" class="embed-responsive-item"></iframe>
                </div>
              </div>
            </div>
            <div class="popup-bg" @click="isPopup = false"></div>
          </div>
        </transition>
      </div>
    </div>
    <span style="display: none;">{{ show_str = (keyName=='CompletionDate' && value!='' && value!=null? value.substr(0,10):value) }}</span>
    <div class="table-data-container" v-if="keyName !== 'reason' && keyName != info_id" v-html="show_str">
      
    </div>
    <div class="table-data-container" v-if="keyName == info_id">
      <div class="jump-link">
      <router-link
        v-if="isFocus === idNum"
        :to="`${jump_url}`"
        class="btn btn-default"
        >
        {{value}}
      </router-link>
    </div>
    </div>


  </td>
</template>

<script>
export default {
  name: 'ListTableRow',
  props: {
    row: Object,
    checkList: [],
    info_id:{
      type:String,
      default:''
    },
    jump_url:{
      type:String,
      default:''
    },
  },
  data() {
    return {
      isPopup: false
    }
  },
  methods: {
    togglePopup: function() {
      setTimeout(() => {
        this.isPopup = !this.isPopup
      }, 10)
    }
  },
}
</script>

<style lang="scss" scoped>
.table.table-striped td {
  min-width: 14.5em;
  white-space: normal !important;
  word-wrap: break-word;
}

.table-data-container {
  max-height: 15rem;
  overflow-y: auto;
  white-space: pre-wrap;
}

.focus-on .focused a {
  color: #fff;
}

.focus-on tr:not(.focused) .btn-white:hover {
  color: #fff;
  background-color: #003f2d;
  border-color: #003f2d;
}

.focus-on .focused a:hover {
  color: #fff;
}

.popup-modal {
  display: block;
}

.popup-bg {
  background: rgba(0, 0, 0, .25);
  height: 100vh;
  left: 0;
  top: 0;
  position: fixed;
  width: 100vw;
  z-index: 8;
}

.popup {
  background: #fff;
  border-radius: .15rem;
  box-shadow: 0 .15rem .5rem rgba(0, 0, 0, .25);
  left: 50% !important;
  max-height: 85vh;
  max-width: 90%;
  position: fixed;
  top: 2.5rem !important;
  transform: translateX(-50%) !important;
  z-index: 16;
}

.popup iframe {
  width: 100%;
}

.fade-leave-active {
  transition: .25s;
  opacity: 0;
}

.fade-enter-active {
  transition: .25s;
  opacity: 0;
}

.fade-enter-to {
  opacity: 1;
}
 .table-data-container div, .table-data-container{
  white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    // width: 200px;
}
.jump-link{
  a{
    border: none;
    background-color: unset;
    text-decoration: underline;
  }
}
</style>